<template>
  <div class="v_task_already g-flex-column">
    <head-back>
      <template v-slot:returnText>
          <span>返回</span>
        </template>
      <template>
        <span>{{titleName}}</span>
      </template>
    </head-back>

    <div class="v-task-already-container">
      <van-list class="v-task-already-list" v-show="orderList.length != 0" v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad" :immediate-check="false">
        <div class="v-task-already-list-item" v-for="(item, index) in orderList" :key="index">
          <div class="v-task-already-list-item-top g-flex-align-center">
            <div class="g-flex-align-center v-task-already-list-item-top-left">
              <!-- 店铺名称 -->
              <i class="iconfont icon-changyonglogo25"></i>
              <p class="v-task-already-item-top-store">{{item.userShop.title | filtersStoreName}}</p>
            </div>
             <!-- 商返 -->
            <div class="v-task-already-list-item-top-right g-flex-align-center">
              <p class="v-task-undone-item-top-midlle-fk-model" :class="item.fk_mode == 0 ? 'v-task-undone-item-top-midlle-fk-model-one': ''">{{item.fk_mode | filtersfkModel }}</p>
            </div>
          </div>

         

          <div class="v-task-already-list-item-bottom  g-flex-align-center">
            <div class="v-task-already-list-item-left">
              <img :src="item.img[0]" alt="">
            </div>
            <div class="v-task-already-list-item-right g-flex-column">
              <div class="v-task-already-item-right-top g-flex-align-center">
                <p class="v-task-already-item-right-top-title g-flex-align-center"><span>{{item.title | filtersStoreName}}</span> </p>
                <span class="v-task-already-item-right-top-status">已完成</span>
              </div>
              <div class="v-task-already-item-right-middle g-flex-align-center">
                <span class="v-task-already-item-right-middle-money">佣金：¥{{item.buy_price}}</span>
                <span class="v-task-already-item-right-middle-time">{{formatDate(item.modify_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
              </div>
              <div class="v-task-already-item-right-middle g-flex-align-center">
                <span class="v-task-revoked-item-right-middle-money">接单账号：{{ item.userAccount.account }}</span>
              </div>
              <div class="v-task-already-item-right-middle g-flex-align-center">
                <span class="v-task-revoked-item-right-middle-money">件数：{{item.buy_num}}件</span>
              </div>
              <div class="v-task-already-item-right-bottom g-flex-justify-end">
                <span class="v-task-already-item-right-bottom-btn"  @click="operaTask(item, 2)">查看详情</span>
              </div>
            </div>
          </div>

        </div>
      </van-list>
        <!-- 任务详情 -->
        <task-details ref="TaskDetails"></task-details>
       <NoList v-show="orderList.length == 0"/> 

    </div>
  </div>
</template>

<script>
  import HeadBack from '@/components/HeadBack.vue'
  import { formatDate } from '@/utils/index.js'
  import { PAGE, LIMIT } from '@/utils/constants.js'
  import { apiGetOrderList } from '@/utils/api.js'
  import TaskDetails from '@/views/taskdianfu/taskdetails/TaskDetails.vue'
  export default {
    components: { HeadBack, TaskDetails },
    data() {
      return {
        routeType: this.$route.params.type,
        num: 10,
        orderList: [],
        form: {
          type: this.$route.params.type,
          status: '4', //all全部 1待操作 2待返款 3待收货 已完成4 已取消5
          page: PAGE,
          limit: LIMIT,
        },
        loading: false,
        finished: false
      }
    },
    created() {
      this.apiGetOrderListHandel()
      console.log(this.$route.params)
    },
    filters: {
      filtersStoreName(dataStr) {
        let str = ''
        for(let i = 0;i<dataStr.length; i++) {
          if(i % 2 === 0) str+= dataStr.substr(i, 1)
          else if(i % 2 === 1) str = str + '*'
        }
        return str
      },

      filtersfkModel(val) {
        if(val == 0) return '平台返款'
        return '商家返款'
      },
    },
    methods: {
      formatDate,
      // 获取任务列表
      async apiGetOrderListHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiGetOrderList(this.form)
        if(!success) return
        this.orderList = this.orderList.concat(data.list)
        this.loading = false
        this.finished = data.list.length < this.form.limit
      },
      onLoad() {
        this.form.page++
        this.apiGetOrderListHandel()
      },
      // 操作任务 点击查看详情 status 1 操作任务  2查看详情
      operaTask(item, status) {
        console.log('操作任务')
        this.$refs.TaskDetails.onShow(item, status)
      },
    },

    computed: {
      titleName() {
        if(this.routeType == '0') return '已完成垫付任务'
        if(this.routeType == '1') return '已完成浏览任务'
        return '已完成预售任务'
      }
    }
  }
</script>

<style lang="scss">
.v_task_already {
  height: 100%;
  background: #F1F1F1;
  .v-task-already-container {
    flex: 1;
    overflow: auto;
    padding: 0 16px;
    position: relative;
    .v-task-already-list {
      .v-task-already-list-item {
        margin-top: 10px;
        padding: 10px 16px;
        background: $white;
        border-radius: 8px;
        .v-task-already-list-item-top {
          .v-task-already-list-item-top-left {
            flex: 1;
            .iconfont {
              font-size: 22px;
              color: $main_color;
            }
            .v-task-already-item-top-store {
              font-size: 15px;
              color: #000000;
              padding-left: 6px;
            }
          }
          
          .v-task-already-list-item-top-right {
            padding-left: 30px;
            .v-task-undone-item-top-midlle-fk-model {
                background: #FA436A;
                color: $white;
                padding: 4px 15px;
                border-radius: 10px;
                font-size: 12px;
                &.v-task-undone-item-top-midlle-fk-model-one {
                    background: #0979a5;
                  }
              }
          }
        }

        

        .v-task-already-list-item-bottom {
          padding-top: 8px;
          .v-task-already-list-item-left {
            img {
              width: 80px;
              height: 80px;
              border-radius: 6px;
              object-fit: cover;
            }
          }

          .v-task-already-list-item-right {
            padding-left: 18px;
            flex: 1;
            .v-task-already-item-right-top {
              font-size: 14px;
              padding-top: 6px;
              .v-task-already-item-right-top-title {
                color: #040404;
                flex: 1;
                width: 150px;
                @include textManyOverflow(2);
                span {
                  flex: 1;
                  word-break: break-all;
                }
              }
              .v-task-already-item-right-top-status {
                color: #FA436A;
                padding-left: 10px;
              }
            }

            .v-task-already-item-right-middle {
              font-size: 12px;
              color: #9E9D9D;
              padding-top: 14px;
              .v-task-already-item-right-middle-time {
                flex: 1;
                padding-left: 10px;
                text-align: right;
              }
            }

            .v-task-already-item-right-bottom {
              margin-top: 14px;
              .v-task-already-item-right-bottom-btn {
                font-size: 12px;
                color: $white;
                padding: 8px 10px;
                border-radius: 5px;
                background: $main_color;
              }
            }
          }
        }

      }
    }
  }
}
</style>